<template>
    <div>
        <div class="buttons">
            <CButton color="success" square
                @click="openModalEvent('create', 'orderNoteForm', null, 'Sipariş Not Formu (Yeni Kayıt)')">
                Ekle
            </CButton>
            <CButton :disabled="lastItem.id === undefined" color="info" square @click="openModalEvent('update', 'orderNoteForm', lastItem, 'Sipariş Not Formu')">
                Düzenle
            </CButton>
            <CButton :disabled="lastItem.id === undefined" color="danger" square @click="openModalEvent('delete', 'confirm', lastItem, 'Sipariş Notunu Sil', '(<b>'+lastItem.note+'</b>) Sipariş notunu silmek istediğinize emin misin?')">
                Sil
            </CButton>
        </div>
        <CDataTable
            :items="notes"
            :fields="noteFields"
            :items-per-page="5"
            pagination
            clickable-rows
            hover
            @row-clicked="rowClicked"
            v-on:refresh="refresh"
            class="modalTable"
        >
        <template #createTime="{item}">
            <td> <span>{{ item.createTime &&  dateFormat(item.createTime) }}</span> </td>
        </template>
        </CDataTable>
        <ConfirmModal
            v-if="form == 'confirm'" 
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="operationEvent"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionType"
            :loading="loading"
            ref="confirm"
        />
        <FormModal 
            v-else
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :operationEvent="operationEvent"
            :actionType="actionType"
            :module="module"
            :form="form"
            :data="modalProps"
        />
    </div>
</template>

<script>
    import FormModal from '../../components/formModal'
    import ConfirmModal from '../../components/confirmModal'
    import moment from 'moment'
    export default{
        name: "NoteList",
        components:{
            FormModal, ConfirmModal
        },
        props:{
            params: Object
        },
        computed:{
            notes: function() {
                return this.$store.getters.userNotes
            },
            paymentLog: function() {
                return this.$store.getters.paymentLog
            },
            loading(){
                return this.$store.getters.orderLoading
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                lastItem: {},
                actionType: '',
                module: 'orders',
                form: 'orderNoteForm',
                modalProps: {},
                modalDesc: '',
                modalTitle: '',
                openModal: false,
                openSidebar: false,
                noteFields: [
                    {key: 'id', label: 'ID', _style: 'font-size:12px'},
                    {key: 'createTime', label: 'Not Zamanı', _style: 'font-size:12px'},
                    {key: 'note', label: 'Not', _style: 'font-size:12px'}
                ],
            }
        },
        methods: {
            openModalEvent(actionType, form, data, title, desc){
                this.openModal = !this.openModal
                this.actionType = actionType
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            async operationEvent(item, action){
                let formData = new FormData()
                formData.append('actionType', action)
                formData.append('noteOnModel', 'nc_order')
                formData.append('oId', this.params.orderId ? this.params.orderId : this.params.id)
                formData.append('id', item.id)
                formData.append('note', item.note)

                await this.$store.dispatch('user_noteCrud', formData)  
                if(this.$store.getters.userStatus.success){
                    this.openModalEvent();
                    this.refresh()
                }
            },
            rowClicked: function(item){
                this.lastItem = item;
            },
            refresh: function(){
                let formData = new FormData();
                formData.append('page', 1)
                formData.append('start', 0)
                formData.append('limit', 25)
                formData.append('sort', 'id')
                formData.append('dir', 'DESC')

                let params = {
                    "noteOnModel": 'nc_order',
                    "modelId": this.params.orderId ? this.params.orderId : this.params.id,
                    formData: formData
                }
                this.$store.dispatch('user_notes', params)
            },
            dateFormat: function(date){
                return moment(date).format('DD.MM.YYYY HH:mm:ss')
            }
        }
    }
</script>
<style scoped>
    .table-responsive{
        height: auto;
    }
   .nav-item{
        margin-right: 1rem;
    }
    .buttons{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .buttons button{
        margin-right: .5rem
    }
</style>